/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
/* eslint-disable react/jsx-props-no-spreading */

import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import {
    object, func, string, shape, bool, arrayOf,
} from 'prop-types';
import { MenuItem } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useUIDSeed } from 'react-uid';
import { transformCharacters } from '../../../../../../helpers/markdown/characterTransformer';
import CategoryForDropDownPmall from './CategoryForDropDownPmall';

const styles = (theme) => ({
    dropdownHeading: {
        color: theme.palette.cms?.primary || theme.palette.colorPrimary,
        fontSize: 13,
        lineHeight: '20px',
        padding: '6px ​12px 6px 12px',
        cursor: 'pointer',
        textDecoration: 'none',
        textRendering: 'optimizeLegibility',
        textShadow: '1px 1px 1px rgba(0, 0, 0, 0.004)',
        '&:active': {
            backgroundColor: 'transparent',
            textDecoration: 'none',
        },
        '&:hover': {
            backgroundColor: theme.palette.cms?.white || '#ffffff',
            textDecoration: 'none',
        },
        '&:focus': {
            backgroundColor: 'transparent',
            textDecoration: 'none',
        },
    },
    dropdownHeadingLink: {
        color: theme.palette.black,
        fontSize: 13,
        fontWeight: 'bold',
        lineHeight: '20px',
        padding: '3px 20px 3px 20px',
        textDecoration: 'none',
        textRendering: 'optimizeLegibility',
        whiteSpace: 'break-spaces',
        textShadow: '1px 1px 1px rgba(0, 0, 0, 0.004)',
        display: 'flex',
        flexWrap: 'wrap',
        '&:active': {
            backgroundColor: 'transparent',
            textDecoration: 'underline',
        },
        '&:hover': {
            backgroundColor: 'transparent',
            textDecoration: 'underline',
        },
        '&:focus': {
            backgroundColor: 'transparent',
            textDecoration: 'underline',
        },
    },

    dropdownMenuItem: {
        color: theme?.palette?.desktopHeaderMenu?.text || '#000',
        fontSize: 13,
        fontWeight: 'normal',
        lineHeight: '20px',
        letterSpacing: '0',
        padding: '3px 0 3px 25px',
        textDecoration: 'none',
        textRendering: 'optimizeLegibility',
        whiteSpace: 'normal',
        textShadow: '1px 1px 1px rgba(0, 0, 0, 0.004)',
        '&:active': {
            backgroundColor: 'transparent',
            textDecoration: 'underline',
        },
        '&:hover': {
            backgroundColor: 'transparent',
            textDecoration: 'underline',
        },
        '&:focus': {
            backgroundColor: 'transparent',
            textDecoration: 'underline',
        },
    },
    dropDownFullWidth: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        '& $dropdownHeadingLink': {
            maxWidth: 165,
            fontSize: 12,
            textDecoration: 'none',
            justifyContent: 'center',
        },
    },
    setGridStch: {
        margin: '0 15px 12px 15px',
        padding: '10px 4px',
        borderBottom: `2px dashed ${theme.palette.cms?.verticalBottomBdrColor || '#dedede'}`,
    },
    setSubMenuLsit: {
        width: 250,
    },
    verticalMenu: {
        backgroundColor: theme.palette.cms?.verticalMenuColor || '#efefe7',
    },
    activeClass: {
        backgroundColor: theme.palette.cms?.white || '#fff',
    },
    menuMainHeading: {
        fontSize: 18,
        fontWeight: 600,
        display: 'block',
        padding: '12px 10px',
    },
});

const V2082021DropDownMenu = ({
    item, classes, actionName, trackEvent, attributes, topMenuItemId, categoryBaseId,
}) => {
    // check the object length
    const checkIsItems = Object.keys(item).length === 0;
    if (checkIsItems) {
        return null;
    }
    const isAbsoluteLink = (link) => link.indexOf('://') !== -1;
    const LINKSTYLE_HEADING = 'Heading';

    const [showOnHover, setOnHover] = useState(true);
    const [showOnHoverSub, setOnHoverSub] = useState(1);
    const [onHoverClass, setOnHoverClass] = useState(1);
    const [categoryOnHover, setCategoryOnHover] = useState(categoryBaseId);
    const seed = useUIDSeed();

    // show the menu category based on hover
    const handleMouseHover = (isHover, col, category) => {
        setOnHover(isHover);
        setOnHoverSub(col);
        setOnHoverClass(col);
        setCategoryOnHover(category);
    };

    // List of submenu
    const showCategoryOnHover = (link, id, columnData, styleOverride) => (
        <Grid item xs={6} className={classes.setSubMenuLsit} data-testid="newTest-Id">
            <MenuItem
                key={seed(columnData)}
                id={id}
                className={columnData?.linkStyle === LINKSTYLE_HEADING ? classes.dropdownHeadingLink : classes.dropdownMenuItem}
                component={link}
                style={styleOverride}
                onClick={() => trackEvent({
                    eventCategory: columnData.track_event?.tracking_event_category || columnData?.trackingCategory || 'Global Nav',
                    eventAction: columnData.track_event?.tracking_event_action || columnData?.trackingAction || actionName || '',
                    eventLabel: columnData.track_event?.tracking_event_label || columnData?.trackingLabel || columnData.label || '',
                    trackEventAction: trackEvent,
                })}
            >
                {
                    columnData.imageUrl
                    && (
                        <div className={classes.subMenuImage}>
                            <img src={columnData?.imageUrl} alt={columnData.label} />
                        </div>
                    )
                }
                {columnData?.textField ? (<span><span style={{ color: columnData?.colorField }}>{transformCharacters(columnData?.textField)} </span>{transformCharacters(columnData?.label)}</span>) : transformCharacters(columnData?.label)}
            </MenuItem>
        </Grid>
    );

    return (
        <Grid container>
            <Grid item xs={3} className={classes.verticalMenu}>
                SDFGSDFGSDFGSDFG
                {item?.submenu?.map((columnItem) => {
                    if (columnItem?.is_vertical_menu_title) {
                        return (
                            <span key={seed(columnItem)} className={classes.menuMainHeading}>
                                { columnItem.label }
                            </span>
                        );
                    }
                    if (columnItem?.linkStyle === LINKSTYLE_HEADING && !columnItem?.link) {
                        return (
                            <>
                                <MenuItem
                                    key={seed(columnItem)}
                                    onMouseOver={() => handleMouseHover(true, columnItem?.column, columnItem?.pim_collection_code)}
                                    className={`${classes.dropdownHeading} ${onHoverClass === columnItem?.column ? classes.activeClass : null}`}
                                    disableRipple
                                >
                                    {columnItem.label}
                                </MenuItem>
                            </>
                        );
                    }
                    return null;
                })}
            </Grid>
            <Grid item xs={9}>
                <Grid container>
                    <Grid item xs={6}>
                        <Grid container>
                            {item?.submenu?.map((columnData, row) => {
                                const menuId = `${topMenuItemId}_${row}`;

                                const styleOverrides = {};

                                if (columnData?.textField) {
                                    styleOverrides.padding = '3px 40px 3px 20px';
                                }

                                if (columnData?.linkStyle === LINKSTYLE_HEADING) {
                                    if (attributes?.dropdown_menu_item_no_wrap) {
                                        styleOverrides.whiteSpace = 'noWrap';
                                    } else if (attributes?.dropdown_menu_item_padding) {
                                        styleOverrides.padding = attributes?.dropdown_menu_item_padding;
                                    }
                                } else if (attributes?.dropdown_menu_item_padding) styleOverrides.padding = attributes?.dropdown_menu_item_padding;

                                // Material UI docs say to do this here as constant instead of inline component={expr ? a : Link}
                                const renderLink = isAbsoluteLink(columnData?.link)
                                // eslint-disable-next-line jsx-a11y/anchor-has-content
                                    ? React.forwardRef((props, ref) => <a aria-hidden="true" target="_blank" rel="noreferrer" ref={ref} href={columnData?.link} {...props} />)
                                    : React.forwardRef((props, ref) => <Link aria-hidden="true" to={columnData?.link} innerRef={ref} {...props} />);

                                if (columnData?.linkStyle === LINKSTYLE_HEADING && columnData?.column === showOnHoverSub && showOnHover) {
                                    return (
                                        <Grid item xs={12}>
                                            <h4 className={classes.setGridStch}>{columnData?.label}</h4>
                                        </Grid>
                                    );
                                }
                                if (!columnData?.is_vertical_menu_title && columnData?.linkStyle !== LINKSTYLE_HEADING && columnData?.column === showOnHoverSub && showOnHover) {
                                    const isEven = row % 2 === 0;

                                    return (
                                        isEven ? showCategoryOnHover(renderLink, menuId, columnData, styleOverrides) : showCategoryOnHover(renderLink, menuId, columnData, styleOverrides)
                                    );
                                }
                                return null;
                            })}
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        <CategoryForDropDownPmall categoryOnHover={categoryOnHover} />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

V2082021DropDownMenu.propTypes = {
    classes: object.isRequired,
    trackEvent: func.isRequired,
    actionName: string.isRequired,
    topMenuItemId: string.isRequired,
    categoryBaseId: string,
    attributes: shape({
        dropdown_menu_item_no_wrap: bool,
        dropdown_menu_item_padding: string,
    }),
    item: shape({
        label: string,
        linkStyle: string,
        main_link: shape({
            href: string,
            title: string,
        }),
        submenu: arrayOf(shape({
            label: string,
            linkStyle: string,
            trackingAction: string,
            trackingCategory: string,
            trackingLabel: string,
        })),
    }),
};
V2082021DropDownMenu.defaultProps = {
    attributes: {},
    item: {},
    categoryBaseId: '',
};

export default (withStyles(styles)(V2082021DropDownMenu));
