/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

// libraries
import React from 'react';
import {
    object, shape, string, arrayOf, number,
} from 'prop-types';
import { Grid, withStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import LazyLoad from 'react-lazyload';
import { useUIDSeed } from 'react-uid';
import PriceRange from '../../../../GraphqlCategoryPage/Partials/GraphqlProductContainer/Partials/GraphqlSimpleProduct/DesktopSimpleProduct/PriceRange';

const styles = (theme) => ({
    root: {
        margin: '24px 15px 50px',
        width: '100%',
        minWidth: '140px',
        height: 'auto',
        textAlign: 'center',
        color: '#fff',
        '-webkit-border-radius': '4px',
        '-moz-border-radius': '4px',
        borderRadius: '4px',
        '-webkit-box-shadow': '0px 11px 19px 5px rgba(0,0,0,0.16)',
        '-moz-box-shadow': '0px 11px 19px 5px rgba(0,0,0,0.16)',
        boxShadow: '0px 11px 19px 5px rgba(0,0,0,0.16)',
        transition: 'transform .2s ease-in-out .2s',
        willChange: 'transform',
        '&:hover': {
            transform: 'scale(1.05)',
        },
    },
    productLink: {
        textDecoration: 'none',
        padding: '16px 10px',
        display: 'block',
    },
    image: {
        '& img': {
            width: '100%',
        },
    },
    copyContainer: {
        padding: '5px 0px',
        fontSize: '12px',
    },
    productName: {
        fontWeight: '600',
        color: theme.palette.cms?.black || '#333',
    },
    productPrice: {
        fontWeight: '400',
        color: theme.palette.cms?.black || '#333',
    },
});
const DropDownPmallProductsUi = ({
    classes,
    productData,
}) => {
    if (!productData) {
        return null;
    }
    const seed = useUIDSeed();
    return (
        <Grid container>
            { productData?.map((item) => (
                <Grid item xs={6} key={seed(item)}>
                    <Link
                        className={classes.productLink}
                        to={item?.seo?.url}
                    >
                        <div className={classes.image}>
                            <LazyLoad>
                                <img src={`${item?.image?.path}${item?.image?.name}z.jpg`} alt={item?.name} />
                            </LazyLoad>
                        </div>
                        <div className={classes.copyContainer}>
                            <div className={classes.productName}>{item?.name}</div>
                            <div className={classes.productPrice}>
                                <PriceRange
                                    presentationFamily="food"
                                    skuPriceRange={item?.skuPriceRange}
                                    isMobile={false}
                                />
                            </div>
                        </div>
                    </Link>
                </Grid>
            ))}
        </Grid>
    );
};
DropDownPmallProductsUi.propTypes = {
    classes: object.isRequired,
    productData: arrayOf(shape({
        id: string.isRequired,
        image: shape({
            path: string.isRequired,
            name: string.isRequired,
        }).isRequired,
        name: string.isRequired,
        skuPriceRange: shape({
            retail: arrayOf(shape({
                value: number.isRequired,
            })).isRequired,
            sale: arrayOf(shape({
                value: number.isRequired,
            })).isRequired,
        }).isRequired,
        seo: shape({
            url: string.isRequired,
        }).isRequired,
    })),
};
DropDownPmallProductsUi.defaultProps = {
    productData: [],
};
export default withStyles(styles)(DropDownPmallProductsUi);
