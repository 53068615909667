/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

// This component renders an item that appears in the desktop
// horizontal header nav menu, and its dropdown submenu when hovered (or clicked) upon
//
// Props:
//    item - the menu item in navmenu object format (see DesktopNavMenu or MenuBuilder)
//    index - index of item within menu.  E.g. index = 3 means this is third item on top menu.
//    length - total # of items on top menu.  Used to compute alignment of dropdown menu.  See computePopperPlacement.
//

/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    bool, shape, string, object, number, func,
} from 'prop-types';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Fade from '@material-ui/core/Fade';
import extractMenuAttributes, { extractHoverAttributes, extractDropDownAttributes } from '../../../../../../helpers/contentstack/extractMenuAttributes';
import customBreakpoints from '../../../../../../helpers/customBreakpoints';
import { transformCharacters } from '../../../../../../helpers/markdown/characterTransformer';
import { getIsBot } from '../../../../../../../state/ducks/App/App-Selectors';
import { actions as tagManagerActions } from '../../../../../../../state/ducks/TagManager';
import { getPresentationFamily } from '../../../../../../../state/ducks/App/ducks/Config/Config-Selectors';
import LinkOrATag from '../../../../GraphqlCommonComponents/LinkOrATag/LinkOrATag';
import PmallDropDownMenu from './PmallDropDownMenu';
import V2082021DropDownMenu from './V2082021DropDownMenu';

const styles = (theme) => ({  // eslint-disable-line arrow-parens
    // spacing: 0,
    topNavItem: {
        listStyle: 'none',
        height: '100%',
        margin: 0,
        padding: 0,
        position: 'relative',
        [theme.breakpoints.down(customBreakpoints.tabLandscape)]: {
            display: 'flex',
            alignItems: 'center',
            fontSize: '11px !important',
            fontFamily: `${['Arial', 'sans-serif'].join(',')} !important`,
            padding: '0',
            '&  > a > button': {
                padding: '0 5px',
            },
            '&  > a > button > span': {
                lineHeight: '1em',
                whiteSpace: 'pre-line',
                wordWrap: 'break-word',
            },
        },
    },
    topMenuLink: {
        textDecoration: 'none',
        display: 'inline-block',
        height: '100%',
    },
    topMenuButton: {
        textTransform: 'uppercase',
        fontSize: '15px',
        letterSpacing: '0',
        padding: '0 10px',
        borderBottom: '3px solid transparent',
        borderTop: '3px solid transparent',
        borderRadius: '0',
        fontFamily: ['Roboto', 'Noto', 'Arial', 'sans-serif'].join(','),
        fontWeight: 'bold',
        transition: 'none',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        color: theme.palette.cms?.black || '#2f2f2f',
        '&:focus': {
            outline: '#dedede',
        },
        '&:hover': {
            backgroundColor: 'transparent',
        },
        '&:active': {
            backgroundColor: 'transparent',
        },

        [theme.breakpoints.down('1130')]: {
            fontSize: '11px !important',
            fontFamily: `${['Arial', 'sans-serif'].join(',')} !important`,
            padding: '0 5px',
        },
        '@media screen and (max-width: 1024px)': {
            padding: '0 5px',
        },

    },
    menuButtonDisplay: {
        [theme.breakpoints.down('1320')]: {
            fontSize: '14px',
            padding: '0 5px',
        },
        [theme.breakpoints.down('1160')]: {
            fontSize: '12px',
        },
    },
    topMenuButtonFocus: {
        borderBottom: 'none',
        backgroundColor: 'transparent',
        '& span': {
            borderBottom: theme.palette.cms?.desktopMenuSepatator || '1px solid #C0B685',
            height: '100%',
        },
    },
    alternateNavItem: {
        '& + li': {
            marginLeft: '0',
        },
        '& button': {
            border: `1px solid ${theme.palette.text.primary}`,
            borderBottom: 'none',
            borderRadius: '5px 5px 0 0',
            top: '-4px',
        },
    },
    topMenuListPopper: {
        backgroundColor: theme.palette.desktopHeaderMenu.background,
        boxShadow: 'none',
        borderRadius: '0',
        zIndex: '100',
        display: 'flex',
    },
    topMenuListPopperBorder: {
        border: theme.palette.cms?.desktopMenuBorder || '1px solid #CCC',
    },
    topMenuListPopperIe: {
        backgroundColor: theme.palette.desktopHeaderMenu.background,
        boxShadow: 'none',
        border: theme.palette.cms?.desktopMenuBorder || '1px solid #CCC',
        borderRadius: '0',
        zIndex: '100',
        display: 'block',
    },
    dropdownHeading: {
        color: theme.palette.cms?.primary || theme.palette.colorPrimary,
        fontSize: '0.78rem',
        fontWeight: 'bold',
        letterSpacing: '0px !important',
        lineHeight: '20px',
        padding: '3px 25px 3px 15px',
        cursor: 'auto',
        textDecoration: 'none',
        textRendering: 'optimizeLegibility',
        textShadow: '1px 1px 1px rgba(0, 0, 0, 0.004)',
        '&:active': {
            backgroundColor: 'transparent',
            textDecoration: 'none',
        },
        '&:hover': {
            backgroundColor: 'transparent',
            textDecoration: 'none',
        },
        '&:focus': {
            backgroundColor: 'transparent',
            textDecoration: 'none',
        },
        '&#Occasions_4_2_0': {
            padding: '0 0 0 5px',
        },
    },
    dropdownHeadingLink: {
        color: theme.palette.black,
        fontSize: '13px',
        fontWeight: 'bold',
        lineHeight: '20px',
        padding: '3px 20px 3px 20px',
        textDecoration: 'none',
        textRendering: 'optimizeLegibility',
        whiteSpace: 'break-spaces',
        textShadow: '1px 1px 1px rgba(0, 0, 0, 0.004)',
        display: 'flex',
        flexWrap: 'wrap',
        '&:active': {
            backgroundColor: 'transparent',
            textDecoration: 'underline',
        },
        '&:hover': {
            backgroundColor: 'transparent',
            textDecoration: 'underline',
        },
        '&:focus': {
            backgroundColor: 'transparent',
            textDecoration: 'underline',
        },
    },

    dropdownMenuItem: {
        color: theme.palette.desktopHeaderMenu.text,
        fontSize: '13px',
        fontWeight: 'normal',
        lineHeight: '20px',
        letterSpacing: '0',
        padding: '3px 0 3px 25px',
        textDecoration: 'none',
        textRendering: 'optimizeLegibility',
        whiteSpace: 'normal',
        textShadow: '1px 1px 1px rgba(0, 0, 0, 0.004)',
        '&:active': {
            backgroundColor: 'transparent',
            textDecoration: 'underline',
        },
        '&:hover': {
            backgroundColor: 'transparent',
            textDecoration: 'underline',
        },
        '&:focus': {
            backgroundColor: 'transparent',
            textDecoration: 'underline',
        },

    },
    banner: {
        padding: '5px 15px 0 0',
        margin: '0',
        display: 'inline-block',
        '& a': {
            '& img': {
                width: '200px',
                height: 'auto',
                marginLeft: '10px',
            },
        },
    },
    bannerIe: {
        padding: '5px 15px 0 0',
        margin: '0',
        display: 'inline-block',
        '& a': {
            '& img': {
                width: '200px',
                height: '265px',
            },
        },
    },
    menuList: {
        display: 'flex',
        padding: '10px 10px 20px 0px',
        '& $dropdownMenuItem': {
            color: theme.palette.desktopHeaderMenu?.text || '#333',
            fontSize: '13px',
            fontWeight: 'normal',
            lineHeight: '20px',
            letterSpacing: '0',
            padding: '3px 0 3px 25px',
            textDecoration: 'none',
            textRendering: 'optimizeLegibility',
            whiteSpace: 'normal',
            textShadow: '1px 1px 1px rgba(0, 0, 0, 0.004)',
            '&:active': {
                backgroundColor: 'transparent',
                textDecoration: 'underline',
            },
            '&:hover': {
                backgroundColor: 'transparent',
                textDecoration: 'underline',
            },
            '&:focus': {
                backgroundColor: 'transparent',
                textDecoration: 'underline',
            },
        },
        '& $dropdownHeading': {
            color: theme.palette.cms?.primary || theme.palette.colorPrimary,
            fontSize: '0.78rem',
            fontWeight: 'bold',
            letterSpacing: '0px !important',
            lineHeight: '20px',
            padding: '3px 25px 3px 15px',
            cursor: 'auto',
            textDecoration: 'none',
            textRendering: 'optimizeLegibility',
            textShadow: '1px 1px 1px rgba(0, 0, 0, 0.004)',
            '&:active': {
                backgroundColor: 'transparent',
                textDecoration: 'none',
            },
            '&:hover': {
                backgroundColor: 'transparent',
                textDecoration: 'none',
            },
            '&:focus': {
                backgroundColor: 'transparent',
                textDecoration: 'none',
            },
            '&#Occasions_4_2_0': {
                padding: '0 0 0 5px',
            },
        },
    },
    menuListSecondary: {
        justifyContent: 'space-around',
    },
    menuListIe: {
        display: 'block',
    },
    dropDownColumn: {
        borderLeft: theme.palette.cms?.desktopMenuSepatator || '1px solid #C0B685',
        '&:first-child': {
            borderLeft: 0,
        },
    },
    outerBackgroundContainer: {
        borderLeft: `1px solid ${theme.palette.cms?.desktopMenuSepatator || '#C0B685'}`,
    },
    innerBackgroundBlock: {
        padding: '3px 0px 3px 20px',
        display: 'grid',
        gridTemplateRows: 'repeat(2, 150px)',
        gridTemplateColumns: 'repeat(2, 1fr)',
        gridGap: 5,
        '& img': {
            padding: '4px',
            '&:first-child': {
                gridRow: '1 / 4',
                height: 295,
                width: 190,
            },
        },
    },
    dropDownMainIe: {
        display: 'inline-block',
        verticalAlign: 'top',
        width: 'auto',
    },
    dropDownIe: {
        display: 'inline-block',
        verticalAlign: 'top',
    },
    customBackdropLeft: {
        zIndex: 10,
        width: '100vw',
        height: '100vh',
        right: '100%',
        position: 'absolute',
        background: theme.palette.bgTextOverlay1,
        [theme.breakpoints.down(customBreakpoints.tabLandscape)]: {
            display: 'none',
        },
    },
    customBackdropRight: {
        right: 'unset',
        left: 0,
        [theme.breakpoints.down(customBreakpoints.tabLandscape)]: {
            display: 'none',
        },
    },
    menuIconImage: {
        width: '25px',
        height: '25px',
        padding: '2px 3px',
    },
    clickAway: {
        background: theme.palette.common?.black,
    },
    subMenuImage: {
        display: 'flex',
        maxWidth: '100%',
        margin: '10px auto',
    },
    dropDownFullWidth: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        '& $dropdownHeadingLink': {
            maxWidth: 165,
            fontSize: 12,
            textDecoration: 'none',
            justifyContent: 'center',
        },
    },
    recipentsClass: {
        display: 'flex',
    },
    setMaxWidth: {
        maxWidth: 920,
    },
    menuSubHeading: {
        fontSize: 13,
        color: theme.palette.cms?.textCategoryColor || '#999999',
        flexBasis: '100%',
    },
    categoryOffer: {
        fontWeight: 600,
        position: 'absolute',
        top: -14,
        fontSize: 10,
        textAlign: 'center',
        width: '100%',
        color: theme.palette.cms?.colorPrimary || '#b01116',
    },

    discountText: {
        paddingLeft: 10,
        fontWeight: 'bold',
        fontSize: 13,
        alignItems: 'baseline',
        padding: '2px 0',
        color: theme.palette.cms?.red || '#dd3337',
        '&:hover': {
            textDecoration: 'none',
        },
    },
    menuItemContainer: {
        display: 'flex',
    },
    headerImage: {
        width: '8%',
        '& $topMenuLink': {
            width: '100%',
        },
        '& $button': {
            width: '100%',
        },
        '& $span': {
            display: 'block',
            paddingTop: '5px',
        },
    },
    menuHeaderImage: {
        width: '100%',
    },
});

// Supported by Composer and CMS/graphql
// const LINKSTYLE_NORMAL = 'Normal';
// const LINKSTYLE_ALTERNATE = 'Alternate';
// const LINKSTYLE_DIVIDER = 'Divider';
const LINKSTYLE_HEADING = 'Heading';

function isAbsoluteLink(link) {
    return link.indexOf('://') !== -1;
}

const DropDownColumn = ({
    item, column, size, topMenuItemId, classes, ieBrowser, actionName, trackEvent, attributes, presentationFamily, columnWidths, isHolidayMenu,
}) => (
    <Grid className={ieBrowser ? classes.dropDownIe : ((presentationFamily === 'food' && classes.dropDownColumn) || (item.fullWidthSubmenu && classes.dropDownFullWidth))} item xs={size} style={{ width: columnWidths }}>
        {item.submenu
            .filter((i) => i.column === column)            // eslint-disable-line arrow-parens
            .map((columnItem, row) => {
                const key = `${topMenuItemId}_${column}_${row + 1 - 1}`;
                const id = `${topMenuItemId}_${column}_${row}`;

                if (columnItem.linkStyle === LINKSTYLE_HEADING && !columnItem.link) {
                    return (
                        <MenuItem key={key} id={id} className={classes.dropdownHeading} disableRipple>
                            {columnItem.label}
                        </MenuItem>
                    );
                }

                const styleOverrides = {};
                if (columnItem.textField) {
                    styleOverrides.padding = '3px 40px 3px 20px';
                }
                if (columnItem?.linkStyle === LINKSTYLE_HEADING) {
                    if (attributes?.dropdown_menu_item_no_wrap) {
                        styleOverrides.whiteSpace = 'noWrap';
                    } else if (attributes?.dropdown_menu_item_padding) {
                        styleOverrides.padding = attributes.dropdown_menu_item_padding;
                    }
                } else if (attributes?.dropdown_menu_item_padding) styleOverrides.padding = attributes?.dropdown_menu_item_padding;

                // Material UI docs say to do this here as constant instead of inline component={expr ? a : Link}
                const renderLink = isAbsoluteLink(columnItem.link)
                    ? React.forwardRef((props, ref) => <a  target="_blank" rel="noreferrer" ref={ref} href={columnItem.link} {...props} />) // eslint-disable-line jsx-a11y/anchor-has-content
                    : React.forwardRef((props, ref) => <Link  to={columnItem.link} innerRef={ref} {...props} />);

                return (
                    <div className={classes.menuItemContainer}>
                        <MenuItem
                            key={key}
                            id={id}
                            className={columnItem.linkStyle === LINKSTYLE_HEADING ? classes.dropdownHeadingLink : classes.dropdownMenuItem}
                            component={renderLink}
                            style={styleOverrides}
                            onClick={() => trackEvent({
                                eventCategory: columnItem.track_event?.tracking_event_category || columnItem?.trackingCategory || 'Global Nav',
                                eventAction: columnItem.track_event?.tracking_event_action || columnItem?.trackingAction || actionName || '',
                                eventLabel: columnItem.track_event?.tracking_event_label || columnItem?.trackingLabel || columnItem?.label || '',
                                trackEventAction: trackEvent,
                            })}
                        >
                            {
                                columnItem?.imageUrl
                                && (
                                    <div className={classes.subMenuImage}>
                                        <img src={columnItem.imageUrl} alt={columnItem.label} />
                                    </div>
                                )
                            }

                            {columnItem?.textField ? (<span><span style={{ color: columnItem.colorField }}>{transformCharacters(columnItem.textField)} </span>{transformCharacters(columnItem.label)}</span>) : transformCharacters(columnItem.label)}
                            {columnItem?.menu_sub_heading && isHolidayMenu && (
                                <div className={classes.menuSubHeading}>
                                    {columnItem?.menu_sub_heading}
                                </div>
                            )}
                        </MenuItem>
                        { columnItem?.discount_text && (
                            <div className={classes.discountText}>{columnItem?.discount_text}</div>
                        )}
                    </div>
                );
            })}
    </Grid>
);

DropDownColumn.propTypes = {
    item: object.isRequired,
    column: number.isRequired,
    size: number.isRequired,
    topMenuItemId: string.isRequired,
    classes: object.isRequired,
    ieBrowser: bool.isRequired,
    actionName: string.isRequired,
    trackEvent: func.isRequired,
    attributes: shape({
        dropdown_menu_item_no_wrap: bool,
        dropdown_menu_item_padding: string,
    }).isRequired,
    presentationFamily: string.isRequired,
    columnWidths: string.isRequired,
    isHolidayMenu: bool.isRequired,
};

export class DesktopTopNavMenuItem extends Component {
    constructor(props) {
        super(props);
        const { index, item } = this.props;
        this.rootId = `${item.label.replace(/ /g, '')}`;
        this.topMenuItemId = `${this.rootId}_${index}`;

        this.state = {
            open: false,
            ieBrowser: false,
            categoryId: '',
        };
        this.focused = false;
    }

    componentDidMount() {
        if (typeof document !== 'undefined') {
            const ieBrowser = /* @cc_on!@ */false || !!document.documentMode;
            this.setState({ ieBrowser });
        }
    }

    handleToggle = () => {
        this.setState((state) => ({ open: !state.open }));
    };

    handleOpen = () => {
        const { item } = this.props;
        if (item?.menu_style === 'Vertical') {
            const categoryNew = item?.submenu.filter((b) => b.linkStyle === 'Heading');
            this.setState({ categoryId: categoryNew?.[0]?.pim_collection_code || '' });
        }

        if (typeof document !== 'undefined') {
            this.focused = true;
            setTimeout(() => {
                if (this.focused && document.getElementById(this.topMenuItemId)) {
                    document.getElementById(this.topMenuItemId).focus();
                    this.setState({ open: true });
                }
            }, 300);
        }
    };

    handleClose = () => {
        this.focused = false;
        this.setState({ open: false });
    };

    handleButtonKeyDown = (event) => {
        const { open } = this.state;

        switch (event.which || event.keyCode) {
            case 38: // up-arrow
                event.preventDefault();
                event.stopPropagation();
                if (open) {
                    this.setState({ open: false });
                }
                break;
            case 40: // down-arrow
                event.preventDefault();
                event.stopPropagation();
                if (!open) {
                    this.handleOpen();
                } else {
                    const el = (typeof document !== 'undefined') ? document.getElementById(`${this.topMenuItemId}_1_1`) : null;
                    if (el) {
                        el.focus();
                    }
                }
                break;
            case 37:
                {
                    // left-arrow
                    event.preventDefault();
                    event.stopPropagation();
                    const { index } = this.props;
                    if (index > 1 && typeof document !== 'undefined') {
                        document.getElementById(`${this.rootId}_${index - 1}`).focus();
                    }
                }
                break;
            case 39:
                {
                    // right-arrow
                    event.preventDefault();
                    event.stopPropagation();
                    const { index } = this.props;
                    const nextMenu = (typeof document !== 'undefined') ? document.getElementById(`${this.rootId}_${index + 1}`) : null;
                    if (nextMenu) {
                        nextMenu.focus();
                    }
                }
                break;

            default:
        }
    };

    // TODO - replace with something that measures popper and positions accordingly.
    // Until then, this simple algorithm works most of the time.
    computePopperPlacement = (index, length) => {
        //  Item on first third of screen
        //   +----+
        //   |item|
        //   +-----------+
        //   | dropdown  |
        if (index <= length / 3) {
            return 'bottom-start';
        }

        //  Item on last third of screen
        //          +----+
        //          |item|
        //   +-----------+
        //   | dropdown  |
        if (index >= (2 * length) / 3) {
            return 'bottom-end';
        }

        //  Item on middle third of screen
        //      +----+
        //      |item|
        //   +----------+
        //   | dropdown |
        return 'bottom';
    };

    PopperPlacement = (index, length) => {
        const { attributes } = this.props;
        switch (attributes.dropdown_placement) {
            case 'Start':
                return 'bottom-start';
            case 'End':
                return 'bottom-end';
            case 'Middle':
                return 'bottom';
            default:
                return this.computePopperPlacement(index, length);
        }
    }

    render() {
        const {
            classes, item, index, length, attributes, trackEvent, isBot, brand, presentationFamily,
        } = this.props;
        if (!item) {
            return null;
        }
        const { open, ieBrowser } = this.state;
        const dropdownId = `${this.rootId}_${index}`;
        const itemStyleOverrides = extractMenuAttributes(attributes);
        const alternateItemStyleOverrides = {
            height: item.additionalHeight > 0 ? `calc(100% + ${item.additionalHeight}px)` : '100%',
        };
        // Add the menuLink Icon Image
        const menuLinkIcon = item.icon ? (
            <>
                <img src={item.icon} alt="MenuIcon" className={classes.menuIconImage} />
                <span>{item.label}</span>
            </>
        )
            : item.label;
        const menuHeaderIconImage = presentationFamily === 'flower' && item.menu_header_image?.url;
        const menuHeader = menuHeaderIconImage ? <img src={item.menu_header_image?.url} alt="MenuImage" className={classes.menuHeaderImage} /> : menuLinkIcon;
        const topNavMenuItemClass = menuHeaderIconImage ? `${classes.topNavItem} ${classes.headerImage}` : classes.topNavItem;
        // The height is only set to main nav, setting height on this div in px does not render properly
        if (itemStyleOverrides.height) {
            itemStyleOverrides.height = '100%';
        }
        if (item.color) {
            itemStyleOverrides.color = item.color;
        }
        if (item.backgroundColor) {
            itemStyleOverrides.backgroundColor = item.backgroundColor;
        }
        if (attributes?.bold_title === false) {
            itemStyleOverrides.fontWeight = 'normal';
        } else itemStyleOverrides.fontWeight = 'bold';

        if (attributes?.font_family) {
            itemStyleOverrides.fontFamily = attributes.font_family?.replace(/font-family: |;/g, '');
        }

        let hoverAttributes = extractHoverAttributes(attributes);
        const dropDownAttributes = extractDropDownAttributes(attributes);
        // TODO: This is temporary for the 18F and 18B as the contentstack is not updated with latest fields.
        if (!Object.keys(hoverAttributes).length > 0) {
            hoverAttributes = {
                ...itemStyleOverrides,
                borderBottomColor: '#53327C',
            };
        } else {
            Object.keys(itemStyleOverrides).forEach((key) => {
                if (!hoverAttributes[key]) {
                    hoverAttributes[key] = itemStyleOverrides[key];
                }
            });
        }

        // # of columns in submenu = column # from last submenu item
        const numberOfColumns = item.submenu && item.submenu.length ? item.submenu[item.submenu.length - 1].column : 0;
        let containerXS = 12;
        let columnXS = 6;
        if (numberOfColumns < 5) {
            columnXS = 12 / numberOfColumns;
        } else {
            columnXS = 2;
            containerXS = numberOfColumns * columnXS;
        }

        const columnRange = new Array(numberOfColumns).fill(undefined).map((_, i) => i + 1); // e.g. if 3 columns, range = [1, 2, 3]
        const { menu_image } = item;
        const menuListStyle = {};
        if (menu_image && menu_image.image !== null && attributes?.background_image_fade) {
            const backgroundColor = attributes.default_child_color?.color ? attributes.default_child_color?.color : '#f4f5f6';
            menuListStyle.background = `linear-gradient(to right, ${backgroundColor} 0%, ${backgroundColor} 50%, ${backgroundColor}00 90%, ${backgroundColor}00 100%), ${backgroundColor} url(${menu_image.image.url}) no-repeat bottom right`;
            menuListStyle.paddingRight = 350;
            menuListStyle.backgroundSize = 'contain';
            menuListStyle.minHeight = attributes.dropdown_minimum_height ? attributes.dropdown_minimum_height : 0;
        }

        const poperdiv = {
            zIndex: 11,
        };

        const columnWidth = (width) => (width ? `${width}px` : 'auto');

        if (item.fullWidthSubmenu) {
            poperdiv.width = '100%';
            menuListStyle.width = '100%';
        }
        if (!item.fullWidthSubmenu) {
            menuListStyle.width = '100%';
            menuListStyle.height = '100%';
            menuListStyle.minWidth = attributes.dropdown_minimum_width ? attributes.dropdown_minimum_width : 0;
            menuListStyle.maxWidth = attributes.dropdown_maximum_width ? attributes.dropdown_maximum_width : '';
            menuListStyle.minHeight = attributes.dropdown_minimum_height ? attributes.dropdown_minimum_height : 0;
            menuListStyle.maxHeight = attributes.dropdown_maximum_height ? attributes.dropdown_maximum_height : '';
        }

        const MenuStyle = item?.menu_style === 'Vertical';
        const holidayStyle = item?.menu_style === 'Holiday Menu';
        const v2082021 = item?.menu_style === '082021';
        let menuListClass = {};
        if (ieBrowser) {
            menuListClass = classes.menuListIe;
        } else if (MenuStyle) {
            menuListClass = classes.recipentsClass;
        } else {
            menuListClass = classes.menuList;
        }

        const navMenuItemDropdown = () => {
            const { categoryId } = this.state;
            return (
                <MenuList id={dropdownId} dense disablePadding className={menuListClass} style={menuListStyle}>
                    <Grid className={ieBrowser ? classes.dropDownMainIe : ''} container item sm={containerXS}>

                        {MenuStyle && presentationFamily === 'food'
                            ? (
                                <Grid container className={classes.setMaxWidth}>
                                    <PmallDropDownMenu
                                        item={item}
                                        key={this.topMenuItemId}
                                        trackEvent={trackEvent}
                                        actionName={item.label}
                                        attributes={attributes}
                                        topMenuItemId={this.topMenuItemId}
                                        categoryBaseId={categoryId}
                                    />
                                </Grid>
                            )
                            : v2082021 ? (
                                <V2082021DropDownMenu
                                    item={item}
                                    key={this.topMenuItemId}
                                    trackEvent={trackEvent}
                                    actionName={item.label}
                                    attributes={attributes}
                                    topMenuItemId={this.topMenuItemId}
                                    categoryBaseId={categoryId}
                                />
                            )
                                : columnRange.map((column) => (
                                    <DropDownColumn
                                        key={`${this.topMenuItemId}_${column}`}
                                        item={item}
                                        column={column}
                                        size={isBot ? 12 : columnXS}
                                        topMenuItemId={this.topMenuItemId}
                                        classes={classes}
                                        ieBrowser={ieBrowser}
                                        trackEvent={trackEvent}
                                        actionName={item.label}
                                        attributes={attributes}
                                        presentationFamily={presentationFamily}
                                        columnWidths={columnWidth(item.columnWidths)}
                                        isHolidayMenu={holidayStyle}
                                    />
                                ))}
                    </Grid>
                    {!attributes?.background_image_fade && menu_image && menu_image.image !== null && menu_image.link.title !== null ? (
                        <Grid
                            className={ieBrowser ? classes.bannerIe : classes.banner}
                            onClick={() => {
                                trackEvent({
                                    eventCategory: menu_image.tracking_event_category,
                                    eventAction: menu_image.tracking_event_action,
                                    eventLabel: menu_image.tracking_event_label,
                                    queued: true,
                                });
                            }}
                        >
                            <LinkOrATag href={menu_image.link.href}>
                                <img src={menu_image.image.url} alt={menu_image.link.title} />
                            </LinkOrATag>
                        </Grid>
                    ) : null}

                    {holidayStyle && item?.holiday_banner_images.length >= 1 && (
                        <Grid className={classes.outerBackgroundContainer}>
                            <div className={classes.innerBackgroundBlock}>
                                {item?.holiday_banner_images.map((data) => (
                                    <img src={`${data.images.url}?height=130&width=160`} alt="" />
                                ))}
                            </div>
                        </Grid>
                    )}
                </MenuList>
            );
        };

        const navMenubrandBase = () => (
            <div
                className={`${ieBrowser ? classes.topMenuListPopperIe : classes.topMenuListPopper} ${!attributes.delineation_border && classes.topMenuListPopperBorder}`}
                style={dropDownAttributes}
            >
                <ClickAwayListener className={classes.clickAway} onClickAway={this.handleClose}>
                    {navMenuItemDropdown()}
                </ClickAwayListener>
            </div>
        );

        const getStyle = (type) => {
            let styleObject = null;
            (item?.style || []).forEach((styleItem) => {
                if (typeof styleItem[type] !== 'undefined' && styleItem[type]?.[type]) {
                    styleObject = styleItem[type][type];
                }
            });
            return styleObject;
        };
        if (getStyle('font_family')) {
            itemStyleOverrides.fontFamily = getStyle('font_family')?.replace('font-family:', '')?.replace(';', '');
            hoverAttributes.fontFamily = getStyle('font_family')?.replace('font-family:', '')?.replace(';', '');
        }
        if (getStyle('font_color')?.color) {
            itemStyleOverrides.color = getStyle('font_color').color;
        }
        if (getStyle('background_color')?.color) {
            itemStyleOverrides.backgroundColor = getStyle('background_color').color;
        }
        if (getStyle('font_hover_color')?.color) {
            hoverAttributes.color = getStyle('font_hover_color').color;
        }
        if (getStyle('background_hover_color')?.color) {
            hoverAttributes.backgroundColor = getStyle('background_hover_color').color;
        }
        if (getStyle('font_weight')) {
            itemStyleOverrides.fontWeight = getStyle('font_weight')?.toLowerCase();
            hoverAttributes.fontWeight = getStyle('fontWeight')?.toLowerCase();
        }

        const navMenuButton = presentationFamily === 'flower' ? `${classes.topMenuButton} ${classes.menuButtonDisplay} ${(open && attributes.delineation_border) && classes.topMenuButtonFocus}` : `${classes.topMenuButton} ${(open && attributes.delineation_border) && classes.topMenuButtonFocus}`;
        const navMenuLabelAndDropDown = () => (
            <>
                {/**
                  * Top Nav Button: button displayed on top nav
                  * on hover, activates menu popover
                */}
                {item.main_link?.href || item.link ? (
                    <Link
                        onClick={() => trackEvent({
                            eventCategory: item.track_event?.tracking_event_category || item.trackingCategory || 'Global Nav',
                            eventAction: item.track_event?.tracking_event_action || item.trackingAction || item.label || '',
                            eventLabel: item.track_event?.tracking_event_label || item.trackingLabel || item.label || '',
                        })}
                        className={classes.topMenuLink}
                        tabIndex="0"
                        to={item.main_link?.href || item.link || ''}
                    >
                        {item?.categoryOffer && (
                            <div className={classes.categoryOffer}>{item?.categoryOffer}</div>
                        )}
                        <div
                            role="button"
                            id={this.topMenuItemId}
                            data-test="top-menu-item"
                            name="topMenuItem"
                            tabIndex="0"
                            className={navMenuButton}
                            style={open ? hoverAttributes : itemStyleOverrides}
                            ref={(node) => {
                                this.anchorEl = node;
                            }}
                            aria-owns={open ? this.topMenuItemId : undefined}
                            aria-haspopup="true"
                            onClick={this.handleToggle}
                            onKeyDown={this.handleButtonKeyDown}
                            disableRipple
                        >
                            {menuHeader}
                        </div>
                    </Link>
                ) : (
                    <div
                        role="button"
                        id={this.topMenuItemId}
                        data-test="top-menu-item"
                        name="topMenuItem"
                        tabIndex="0"
                        className={navMenuButton}
                        style={open ? hoverAttributes : itemStyleOverrides}
                        ref={(node) => {
                            this.anchorEl = node;
                        }}
                        aria-owns={open ? this.topMenuItemId : undefined}
                        aria-haspopup="true"
                        onClick={() => {
                            trackEvent({
                                eventCategory: item.track_event?.tracking_event_category || item.trackingCategory || 'Global Nav',
                                eventAction: item.track_event?.tracking_event_action || item.trackingAction || item.label || '',
                                eventLabel: item.track_event?.tracking_event_label || item.trackingLabel || item.label || '',
                            });
                            this.handleToggle();
                        }}
                        onKeyDown={this.handleButtonKeyDown}
                        disableRipple
                    >
                        {menuHeader}
                    </div>
                )}
                {/**
                  * Menu Popover: activates on button hover
                  * shows popover only if submenu exists or an image exists (e.g 18f community tab)
                */}

                {open && (item.submenu?.length || item.menu_image?.image?.url) ? (
                    <>
                        <div className={classes.customBackdropLeft} />
                        <div className={`${classes.customBackdropLeft} ${classes.customBackdropRight}`} />
                        <Popper
                            placement={this.PopperPlacement(index, length)}
                            open={open}
                            anchorEl={this.anchorEl}
                            disablePortal={false}
                            onMouseEnter={this.handleOpen}
                            onMouseLeave={this.handleClose}
                            modifiers={{
                                flip: {
                                    enabled: false,
                                },
                                preventOverflow: {
                                    enabled: true,
                                    boundariesElement: 'window',
                                },
                            }}
                            transition
                            style={poperdiv}
                        >
                            {presentationFamily === 'food'
                                ? ({ TransitionProps }) => (
                                    <Fade {...TransitionProps} timeout={400}>
                                        {navMenubrandBase()}
                                    </Fade>
                                ) : (
                                    navMenubrandBase()
                                )}
                        </Popper>
                    </>
                ) : null}
            </>
        );

        // Also tried to trigger menu with onFocus/onBlur for better ADA, but it would capture link clicks so had to take it out -- Seth
        let response = (
            <>
                {(item.main_link && item.main_link.href) || item.link ? (
                    <li
                        className={`${topNavMenuItemClass} ${item.linkStyle === 'Alternate' && brand?.code === 'HD' ? classes.alternateNavItem : ''} `}
                        style={alternateItemStyleOverrides}
                        onMouseEnter={this.handleOpen}
                        onMouseLeave={this.handleClose}
                        onTouchEnd={this.handleOpen}
                        data-testid={item.label}
                    >
                        {navMenuLabelAndDropDown()}
                    </li>
                ) : (
                    <li className={topNavMenuItemClass} onMouseEnter={this.handleOpen} onMouseLeave={this.handleClose}>
                        {navMenuLabelAndDropDown()}
                    </li>
                )}
            </>
        );

        if (isBot) {
            response = (
                <li>
                    <Link  to={item?.main_link?.href || ''} className={classes.topMenuLink} onMouseEnter={this.handleOpen} onMouseLeave={this.handleClose}>
                        <Button
                            id={this.topMenuItemId}
                            data-test="top-menu-item"
                            name="topMenuItem"
                            tabIndex="0"
                            className={`${classes.topMenuButton}  ${attributes.secondary && classes.menuListSecondary}`}
                            style={itemStyleOverrides}
                            buttonRef={(node) => {
                                this.anchorEl = node;
                            }}
                            aria-owns={open ? dropdownId : undefined}
                            aria-haspopup="true"
                            onClick={this.handleToggle}
                            onKeyDown={this.handleButtonKeyDown}
                            disableRipple
                        >
                            {item.label}
                        </Button>
                    </Link>
                    {navMenuItemDropdown()}
                </li>
            );
        }
        return response;
    }
}

const mapStateToProps = (state) => ({
    isBot: getIsBot(state),
    presentationFamily: getPresentationFamily(state),
});

const mapDispatchToProps = {
    trackEvent: tagManagerActions.trackEvent,
};

DesktopTopNavMenuItem.propTypes = {
    attributes: shape({
        background_image_fade: bool,
        dropdown_menu_item_no_wrap: bool,
        dropdown_menu_item_padding: string,
        dropdown_minimum_width: string,
        dropdown_minimum_height: number,
    }),
    item: object.isRequired,
    index: number.isRequired,
    length: number.isRequired,
    classes: object.isRequired,
    trackEvent: func.isRequired,
    columnWidths: string,
    presentationFamily: string,
    isBot: bool,
    brand: shape({
        code: string,
    }),
};

DesktopTopNavMenuItem.defaultProps = {
    attributes: {},
    isBot: false,
    brand: {
        code: '',
    },
    presentationFamily: '',
    columnWidths: '',
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DesktopTopNavMenuItem));
