/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

// libraries
import React from 'react';
import {
    object,
    string,
} from 'prop-types';
import { useQuery } from '@apollo/client';
import { connect } from 'react-redux';
import mbpLogger from 'mbp-logger';

// UI Components
import DropDownPmallProductsUi from './DropDownPmallProductsUi';

// redux, hooks, helpers, data
import { getBrand } from '../../../../../../../state/ducks/App/ducks/Brand/Brand-Selectors';
import { GRAPHQL_ENV } from '../../../../../../gql';
import findCategoryById from '../../../../../../gql/queries/findCategoryById';
import useExperimentServiceAttributes from '../../../../../../helpers/experimentService/useExperimentServiceAttributes';

const CategoryForDropDownPmall = ({
    brand, categoryOnHover,
}) => {
    const { targeting, context, isGraphqlTargetingEnabled } = useExperimentServiceAttributes({ queryName: 'findCategoryById' });
    const PMALLCATEGORY_PRODUCTS_QUERY = findCategoryById(isGraphqlTargetingEnabled);
    const variables = {
        brand: brand['domain-name'],
        environment: GRAPHQL_ENV,
        locale: 'en-us',
        id: categoryOnHover,
        productOptions: {
            pageSize: 2,
            page: 1,
        },
        ...(isGraphqlTargetingEnabled ? { targeting } : {}),
    };

    const { data, loading, error } = useQuery(PMALLCATEGORY_PRODUCTS_QUERY, { variables, context });

    if (loading) {
        return null;
    }

    if (error) {
        mbpLogger.logError({
            appName: process.env.npm_package_name,
            query: PMALLCATEGORY_PRODUCTS_QUERY,
            component: 'CategoryForDropDownPmall.js',
            message: 'Error loading data from Graphql',
            env: GRAPHQL_ENV,
            error,
        });
        return null;
    }

    if (!data) {
        mbpLogger.logWarning({
            appName: process.env.npm_package_name,
            query: PMALLCATEGORY_PRODUCTS_QUERY,
            component: 'CategoryForDropDownPmall.js',
            message: 'No data returned for query',
            env: GRAPHQL_ENV,
        });
        return null;
    }

    const productData = data?.findCategoryById?.products || [];

    return (
        <DropDownPmallProductsUi
            productData={productData}
        />
    );
};

CategoryForDropDownPmall.propTypes = {
    brand: object.isRequired,
    categoryOnHover: string,
};
CategoryForDropDownPmall.defaultProps = {
    categoryOnHover: '',
};
const mapStateToProps = (state) => ({
    brand: getBrand(state),
});

export default connect(mapStateToProps, null)(CategoryForDropDownPmall);
